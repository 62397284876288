.og-container {
  //border-top: 0.5px solid #dedede;
  &.compact {
    display: flex;
    flex-direction: row;
    .og-image-wrapper {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      margin-top: 10px;
      padding-top: 0;
    }
    .og-info {
      padding: 0;
      flex: 1;
    }
  }
}

.og-image-wrapper {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 51.25%;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 0.5px solid #dedede;
  border-radius: 5px;
}

.og-info {
  padding-top: 15px;
}

.og-site-name {
  display: block;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: #616770;
  //font-family: "Roboto", sans-serif;
}

.og-title {
  display: block;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 15px;
  //font-family: "Roboto", sans-serif;
  color: #1d2129;
  margin-top: 3px;
  margin-bottom: 10px;
}

.og-description {
  display: block;
  //font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 13px;
  color: #616770;
}
